import stylesTema from "styles/Tema.module.scss";
import Menu from "components/Menu";
import Header from "components/Header";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Spinner from "components/Spinner";
import ToastContainer from "components/ToastContainer";
import EStrings from "enums/EStrings";
import {UsuariosService} from "services/UsuariosService";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import Footer from "components/Footer";
import {Dialog, DialogFooter, DialogBody, DialogHeader, Typography} from "@material-tailwind/react";
import {BaseHelper} from "helpers/BaseHelper";
import {HiOutlineInformationCircle} from "react-icons/hi2";
import {ParametrosService} from "services/ParametrosService";
import {ToastHelper} from "helpers/ToastHelper";
import ButtonDefault from "components/Buttons/Default";
import useData from "../../contexts/dataProvider/useData";

const DefaultLayout = () => {
	const {user, setUser, auth} = useData();

	const tokenVisao = auth?.token;
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [openTermoUso, setOpenTermoUso] = useState<boolean>(false);

	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	useEffect(() => {
		if (user) {
			if (!user.aceito_termo) {
				setOpenTermoUso(true);
			}
		}
	}, [user]);

	const handleTermoUso = async () => {
		setIsLoading(true);

		try {
			const result = await ParametrosService.buscarTermosUso(tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				setIsLoading(false);
				return;
			}

			window.open(body.data.url, "_blank");
			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setIsLoading(false);
				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setIsLoading(false);
			return;
		}
	};

	const handleConcordarTermoUso = async () => {
		setDesabilitarBotao(true);
		setIsLoading(true);

		try {
			const result = await UsuariosService.aceitarTermo(tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoading(false);
					setDesabilitarBotao(false);

					return;
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}

			ToastHelper("success", "Usuário aceitou os termos de uso com sucesso.");

			if (user !== null) {
				setUser({
					...user,
					aceito_termo: true
				});
			}

			setDesabilitarBotao(false);
			setOpenTermoUso(false);

			return;
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);

				setIsLoading(false);
				setDesabilitarBotao(false);

				return;
			}
		}
	};

	return (
		<main className={"container_principal"}>
			<Header setOpenMenu={setOpenMenu}/>
			{isLoading &&
				<Spinner/>
			}

			{openTermoUso &&
				<Dialog open={openTermoUso} handler={() => console.log("quis fechar")} size={"xs"} animate={BaseHelper.AnimationDialog}>
					<DialogHeader className={"text-center"}>
						<HiOutlineInformationCircle className={"w-32 h-32 mr-auto ml-auto"} color={"#3fc3ee"}/>
					</DialogHeader>
					<DialogBody>
						<Typography variant={"paragraph"} className={"text-monospace"}>
							Ao concordar com os <span className={"text-amber-500 cursor-pointer"} onClick={() => handleTermoUso()}>Termos de Uso</span>, o usuário declara estar ciente e de acordo com as regras e políticas de privacidade do nosso site.
						</Typography>
					</DialogBody>
					<DialogFooter>
						<ButtonDefault variant={"gradient"} color={"green"} description={"Concordar"} desabilitar={desabilitarBotao} onClick={handleConcordarTermoUso}/>
					</DialogFooter>
				</Dialog>
			}

			{!isLoading && user?.aceito_termo &&
				<div className={stylesTema.container}>
					<Menu setOpenMenu={setOpenMenu} turnOn={openMenu}/>
					<Outlet/>
					<ToastContainer/>
				</div>
			}

			<Footer setLoading={setIsLoading}/>
		</main>
	);
};

export default DefaultLayout;