interface IPrimeiroAcessoCommand {
	email: string;
	token: string;
	senha: string | null;
}

const createPrimeiroAcessoDefault = (overrides: Partial<IPrimeiroAcessoCommand>) => ({
	email: "",
	token: "",
	senha: null,
	...overrides
});

export {createPrimeiroAcessoDefault};
export type {IPrimeiroAcessoCommand};