import {useNavigate} from "react-router-dom";
import {FormEvent, useState} from "react";
import styles from "./PrimeiroAcesso.module.scss";
import Input from "components/Inputs/Default";
import ButtonDefault from "components/Buttons/Default";
import ButtonSubmit from "components/Buttons/Submit";
import {ToastHelper} from "helpers/ToastHelper";
import {UsuariosService} from "services/UsuariosService";
import ERotas from "enums/ERotas";
import EStrings from "enums/EStrings";
import {createPrimeiroAcessoDefault, IPrimeiroAcessoCommand} from "interfaces/Commands/UsuariosCommands/IPrimeiroAcessoCommand";
import Spinner from "../../../components/Spinner";
import Password from "../../../components/Inputs/Password";

const UsuarioPrimeiroAcesso = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState<boolean>(false);

	const [data, setData] = useState<IPrimeiroAcessoCommand>(createPrimeiroAcessoDefault({}));
	const [isCriarSenha, setIsCriarSenha] = useState<boolean>(false);

	const [visiblePassword, setVisiblePassword] = useState<boolean>(false);

	const handleAcessarPagina = (rota: string) => {
		navigate(rota);
	};

	const handleInputChange = (name: string, value: string) => {
		setData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!isCriarSenha) {
			await validarTokenPrimeiroAcesso();
		} else {
			await atualizarPrimeiroAcesso();
		}
	};

	const validarTokenPrimeiroAcesso = async () => {
		setLoading(true);

		if (data.email === null || data.email === "") {
			setLoading(false);
			ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);

			return;
		}

		if (data.token === null || data.token === "") {
			setLoading(false);
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);

			return;
		}

		await UsuariosService.primeiroAcesso(data).then(result => {
			if (result == null) {
				ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});
				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
				return;
			}

			const data = body.data;

			if (!data.sucesso) {
				ToastHelper("warning", EStrings.ERRO_PRIMEIRO_ACESSO);
				return;
			}

			setIsCriarSenha(true);
		}).catch(error => {
			ToastHelper("error", error.message);
		}).finally(() => {
			setLoading(false);
		});
	};

	const atualizarPrimeiroAcesso = async () => {
		setLoading(true);

		if (data.email == null || data.email == "") {
			ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);
			setLoading(false);
			return;
		}

		if (data.token == null || data.token == "") {
			ToastHelper("warning", EStrings.TOKEN_OBRIGATORIO);
			setLoading(false);
			return;
		}

		if (data.senha == null || data.senha == "") {
			ToastHelper("warning", EStrings.SENHA_OBRIGATORIO);
			setLoading(false);
			return;
		}

		await UsuariosService.atualizarSenha(data).then(result => {
			if (result == null) {
				ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			const data = body.data;

			if (!data.sucesso) {
				ToastHelper("warning", EStrings.ERRO_PRIMEIRO_ACESSO);

				return;
			}

			ToastHelper("success", EStrings.SUCESSO_RECUPERAR_SENHA);

			navigate(ERotas.LOGIN);
		}).catch(error => {
			ToastHelper("error", error.message);
		}).finally(() => {
			setLoading(false);
		});
	};

	return (
		<>
			{loading &&
				<Spinner/>
			}

			<form className={styles.form} id={"formPrimeiroAcesso"} onSubmit={enviarFormulario} noValidate>
				<div className={"grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-7"}>
					<div className={"sm:col-span-12"}>
						<Input
							type={"email"}
							label={"E-mail"}
							onChange={e => handleInputChange("email", e.target.value)}
							required={true}
							readonly={isCriarSenha}
						/>
					</div>

					<div className={"sm:col-span-12"}>
						<Input
							type={"text"}
							label={"Token"}
							onChange={e => handleInputChange("token", e.currentTarget.value)}
							required={true}
							readonly={isCriarSenha}
						/>
					</div>

					{isCriarSenha &&
						<div className={"sm:col-span-12"}>
							<Password
								label={"Senha"}
								setVisible={setVisiblePassword}
								visible={visiblePassword}
								onChange={e => handleInputChange("senha", e.currentTarget.value)}
								required={true}
							/>
						</div>
					}

					<div className={"sm:col-span-12"}>
						<ButtonSubmit fullWidth={true} form={"formPrimeiroAcesso"} description={"Verificar"}/>
					</div>
				</div>

				<hr className={"mt-4 mb-1"}/>
			</form>

			<ButtonDefault variant={"text"} description={"Login"} onClick={() => handleAcessarPagina(ERotas.LOGIN)}/>
		</>
	);
};

export default UsuarioPrimeiroAcesso;