import {Api} from "providers/Api";
import {ApiResult} from "interfaces/ApiResult";
import {ILoginCommand} from "interfaces/Commands/UsuariosCommands/ILoginCommand";
import {ILoginResult} from "interfaces/CommandsResults/UsuariosResults/ILoginResult";
import {IBuscarDadosResult} from "interfaces/CommandsResults/UsuariosResults/IBuscarDadosResult";
import {IRecuperarSenhaCommand} from "interfaces/Commands/UsuariosCommands/IRecuperarSenhaCommand";
import {IRecuperarSenhaResult} from "interfaces/CommandsResults/UsuariosResults/IRecuperarSenhaResult";
import {ISolicitarRecuperarSenhaCommand} from "interfaces/Commands/UsuariosCommands/ISolicitarRecuperarSenhaCommand";
import {ISolicitarRecuperarSenhaResult} from "interfaces/CommandsResults/UsuariosResults/ISolicitarRecuperarSenhaResult";
import {IBuscarDadosUsuarioCommand} from "interfaces/Commands/UsuariosCommands/IBuscarDadosUsuarioCommand";
import {IBuscarDadosUsuarioResult} from "interfaces/CommandsResults/UsuariosResults/IBuscarDadosUsuarioResult";
import {ISalvarAtualizarCommand} from "interfaces/Commands/UsuariosCommands/ISalvarAtualizarCommand";
import {ISalvarAtualizarResult} from "interfaces/CommandsResults/UsuariosResults/ISalvarAtualizarResult";
import {IListarResult} from "interfaces/CommandsResults/UsuariosResults/IListarResult";
import {IPrimeiroAcessoCommand} from "interfaces/Commands/UsuariosCommands/IPrimeiroAcessoCommand";
import {IPrimeiroAcessoResult} from "interfaces/CommandsResults/UsuariosResults/IPrimeiroAcessoResult";
import {IAtualizarSenhaResult} from "interfaces/CommandsResults/UsuariosResults/IAtualizarSenhaResult";
import {ILogoutResult} from "interfaces/CommandsResults/UsuariosResults/ILogoutResult";
import {IAceitoTermoUsoResult} from "interfaces/CommandsResults/UsuariosResults/IAceitoTermoUsoResult";
import {IBuscarTokenizacaoCommand} from "interfaces/Commands/UsuariosCommands/IBuscarTokenizacaoCommand";
import {IPerfilCommand} from "interfaces/Commands/UsuariosCommands/IPerfilCommand";
import {IPerfilResult} from "interfaces/CommandsResults/UsuariosResults/IPerfilResult";
import { IBuscarEstatisticaResult } from "interfaces/CommandsResults/UsuariosResults/IBuscarEstatisticaResult";

const controller = "/usuarios";

const login = (data: ILoginCommand) => Api.post<ApiResult<ILoginResult>>(`${controller}/login`, data);
const solicitarRecuperarSenha = (data: ISolicitarRecuperarSenhaCommand) => Api.post<ApiResult<ISolicitarRecuperarSenhaResult>>(`${controller}/solicitarRecuperarSenha`, data);
const recuperarSenha = (data: IRecuperarSenhaCommand) => Api.post<ApiResult<IRecuperarSenhaResult>>(`${controller}/recuperarSenha`, data);
const primeiroAcesso = (data: IPrimeiroAcessoCommand) => Api.post<ApiResult<IPrimeiroAcessoResult>>(`${controller}/primeiroAcesso`, data);
const atualizarSenha = (data: IPrimeiroAcessoCommand) => Api.post<ApiResult<IAtualizarSenhaResult>>(`${controller}/atualizarSenha`, data);
const buscarDados = (token: string) => Api.post<ApiResult<IBuscarDadosResult>>(`${controller}/buscar`, {}, {
	headers: {
		token: token
	}
});
const buscarDadosUsuario = (data: IBuscarDadosUsuarioCommand, token: string) => Api.post<ApiResult<IBuscarDadosUsuarioResult>>(`${controller}/buscarDadosUsuario`, data, {
	headers: {
		token: token
	}
});
const salvarAtualizar = (data: ISalvarAtualizarCommand, token: string) => Api.post<ApiResult<ISalvarAtualizarResult>>(`${controller}/salvarAtualizar`, data, {
	headers: {
		token: token
	}
});
const listar = (token: string) => Api.post<ApiResult<IListarResult[]>>(`${controller}/listar`, {}, {
	headers: {
		token: token
	}
});
const logout = (token: string) => Api.post<ApiResult<ILogoutResult>>(`${controller}/logout`, {}, {
	headers: {
		token: token
	}
});
const aceitarTermo = (token: string) => Api.post<ApiResult<IAceitoTermoUsoResult>>(`${controller}/aceitarTermo`, {}, {
	headers: {
		token: token
	}
});
const buscarTokenizacao = (data: IBuscarTokenizacaoCommand) => Api.post<ApiResult<ILoginResult>>(`${controller}/buscarTokenizacao`, data);
const perfil = (data: IPerfilCommand) => Api.post<ApiResult<IPerfilResult>>(`${controller}/perfil`, data);
const buscarEstatisticas = (token: string) => Api.post<ApiResult<IBuscarEstatisticaResult>>(`${controller}/buscarEstatisticas`, {}, {
	headers: {
		token: token
	}
});

export const UsuariosService = {
	login,
	solicitarRecuperarSenha,
	recuperarSenha,
	primeiroAcesso,
	atualizarSenha,
	buscarDados,
	buscarDadosUsuario,
	salvarAtualizar,
	listar,
	logout,
	aceitarTermo,
	buscarTokenizacao,
	perfil,
	buscarEstatisticas
};
